.navbar {
  display: flex;
  align-items: center;
  padding: 1rem 6rem;
}
.navbar-border {
  height: 2px;
  width: 100%;
  background-color: var(--color-text);
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-links_container {
  display: flex;
  justify-content: flex-end;
}

.navbar-links_logo p {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 28.43px;
  line-height: 43px;
  color: var(--color-light);
  cursor: pointer;
}

.navbar-links_container p {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: var(--color-white);
  margin-left: 8rem;
}
.navbar-menu_toggle {
  display: none;
} 

@media screen and (max-width: 1050px) {
  .navbar-links_container p {
    margin-left: 4rem;
  }
  .navbar{
    padding: 1rem 3rem;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    display: flex;
    align-items: center;
    padding: 1rem 2.5rem;
  }

  .navbar-links_container {
    display: none;
  }

  .navbar-menu_toggle {
    display: flex;
  }

  .navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    text-align: end;
    padding:0.5rem 1rem;
    color: white;
    position: absolute;
    top: 3.5rem;
    right:2.5rem;
    min-width: 80px;
    background-color: var(--color-text);
    border-radius: 5px;
    z-index: 1;
}
.navbar-links_container-links{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.navbar-menu_container-links p{
      font-family: var(--font-primary);
      font-size: 18px;
      padding: 0.5rem;
  }
  .navbar-menu_container-links p:hover{
    color: var(--color-bg);
    font-size: 18px;
    font-weight: 500;
}
  
}

@media screen and (max-width: 500px) {
}
