.footer {
  background: var(--color-primary);
  display: flex;
  flex-direction: column;
}
.footer-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.footer p {
  margin: 1rem 0 1rem 0;
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: var(--color-light);
}

@media screen and (max-width: 700px) {
  .footer p {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: var(--color-light);
  }

  .footer-social {
    gap: 1rem;
  }

  .footer-social img{
    width: 1.5rem;
  }
}
