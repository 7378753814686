.header {
  padding: 8rem 6rem;
  justify-content: space-between;
  align-items: end;
  display: flex;
  flex-direction: row;
  color: white;
}

.header-graphics {
  display: flex;
  justify-content: flex-end;
}

.header-graphics img{
  max-width: 350px;
}

.header-content {
  display: flex;
  flex-direction: column;
}
.header h1 {
  font-family: var(--font-primary);
  font-weight: 800;
  font-size: 60px;
  line-height: 100px;
  color: var(--color-text);
}

.header h2 {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 37.9px;
  line-height: 57px;
  color: var(--color-light);
}

.header-content_btn {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.header-content_btn-folio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4.56484px 9.12968px;
  gap: 5.71px;
  width: 228.97px;
  height: 52.13px;
  background: var(--color-text);
  border-radius: 5px;
}
.header-content_btn-folio p {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 28.43px;
  line-height: 43px;
  color: var(--color-bg);
}

.header-content_btn-talk p {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 28.43px;
  line-height: 43px;
  color: var(--color-bg);
}

.header-content_btn-talk {
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4.56484px 9.12968px;
  gap: 5.71px;

  width: 191.97px;
  height: 52.13px;


  background: var(--color-light);
  border-radius: 4.56484px;
}



.header-content-social {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 22.82px;

  width: 143px;
  height: 34px;
}

@media screen and (max-width: 1150px) {
  .header {
    padding: 4rem 6rem;
  }
  .header-graphics img{
    max-width: 300px;
  }
  .header h1 {
    font-size: 48px;
  }
  .header h2 {
    margin: 0;
    padding: 0;
    font-size: 28px;
  }

  .header-content_btn {
    margin-top: 1rem;
  }

  .header-content_btn-folio {
    padding: 1px 1px;
    width: 160px;
    height: 45px;
  }
  .header-content_btn-folio p {
    font-size: 18px;
  }

  .header-content_btn-talk {
    padding: 1px;
    width: 160px;
    height: 45px;
  }

  .header-content_btn-talk p {
    font-size: 18px;
  }
}

@media screen and (max-width: 1050px){
  .header {
    padding: 4rem 3rem;
    align-items: center;
  }
  .header-graphics img{
    max-width: 350px;
  }
  .header-content-social img{
    width: 1.5rem;
  }
  .header-content-social{
    justify-content: flex-start;
    margin-top: 1rem;
  }
  
}
  


@media screen and (max-width: 900px) {
  .header {
    flex-direction: column-reverse;
    padding: 2rem 2rem;
    justify-content: center;
    align-items: center;
  }
  .header-graphics{
    justify-content: center;
  }

  .header-graphics img {
    max-width: 200px;
  }
  .header h1 {
    font-size: 38px;
    padding-top: 40px;
    line-height: 38px;
  }
  .header h2 {
    padding-top: 0;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 24px;
    font-size: 20px;
  }
  .header-content{
    align-items: center;
  }

  .header-content_btn {
    margin-top: 0.5rem;
  }

  .header-content_btn-folio {
    padding: 1px 1px;
    width: 120px;
    height: 35px;
    border-radius: 16px;
  }
  .header-content_btn-folio:hover{
     border: 2px solid var(--color-light);
     width: 116px;
    height: 31px;
  }
   .header-content_btn-talk {
    padding: 1px 1px;
    width: 120px;
    height: 35px;
    border-radius: 16px;
  }

   .header-content_btn-talk:hover{
     border: 2px solid var(--color-text);
     width: 116px;
      height: 31px;
  }


  .header-content_btn-folio p {
    font-size: 12px;
  }
  .header-content_btn-talk p {
    font-size: 12px;

  }

  .header-content_btn-folio img{
    width: 1.2rem;
  }

  .header-content_btn-talk img{
    width: 1.2rem;
  }



}

@media screen and (max-width: 500px) {

}
