@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;500;600;700;800&display=swap);
:root{
  --font-primary:'Poppins',sans-serif;
  --font-secondary:'Open-Sans',sans-serif;

  --gradient-text: linear-gradient(49deg, #DED6FF 0%, #9074FF 93.93%);
  --color-bg:#0A0324;
  --color-text:#8970EF;
  --color-light:#DED6FF;
  --color-white:#fff;

}

html {
  -webkit-tap-highlight-color: transparent;
}


*{
  margin: 0;
  padding:0;
  scroll-behavior: smooth;
}

body{
  background-color: var(--color-bg);
}

a{
  color: unset;
  text-decoration: none;
}

.section__padding{
  padding: 4rem 8rem;
}

.section__margin{
  margin: 4rem 8rem;
}


@media screen and (max-width: 700px) {
  .section__padding{
      padding: 4rem;
  }
  .section__margin{
      margin: 4rem;
  } 
}

@media screen and (max-width: 550px) {
  .section__padding{
      padding: 4rem 2rem;
  }
  .section__margin{
      margin: 4rem 2rem;
  } 
}
.footer {
  background: var(--color-primary);
  display: flex;
  flex-direction: column;
}
.footer-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.footer p {
  margin: 1rem 0 1rem 0;
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: var(--color-light);
}

@media screen and (max-width: 700px) {
  .footer p {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: var(--color-light);
  }

  .footer-social {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .footer-social img{
    width: 1.5rem;
  }
}

.header {
  padding: 8rem 6rem;
  justify-content: space-between;
  align-items: end;
  display: flex;
  flex-direction: row;
  color: white;
}

.header-graphics {
  display: flex;
  justify-content: flex-end;
}

.header-graphics img{
  max-width: 350px;
}

.header-content {
  display: flex;
  flex-direction: column;
}
.header h1 {
  font-family: var(--font-primary);
  font-weight: 800;
  font-size: 60px;
  line-height: 100px;
  color: var(--color-text);
}

.header h2 {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 37.9px;
  line-height: 57px;
  color: var(--color-light);
}

.header-content_btn {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.header-content_btn-folio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4.56484px 9.12968px;
  grid-gap: 5.71px;
  gap: 5.71px;
  width: 228.97px;
  height: 52.13px;
  background: var(--color-text);
  border-radius: 5px;
}
.header-content_btn-folio p {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 28.43px;
  line-height: 43px;
  color: var(--color-bg);
}

.header-content_btn-talk p {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 28.43px;
  line-height: 43px;
  color: var(--color-bg);
}

.header-content_btn-talk {
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4.56484px 9.12968px;
  grid-gap: 5.71px;
  gap: 5.71px;

  width: 191.97px;
  height: 52.13px;


  background: var(--color-light);
  border-radius: 4.56484px;
}



.header-content-social {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  grid-gap: 22.82px;
  gap: 22.82px;

  width: 143px;
  height: 34px;
}

@media screen and (max-width: 1150px) {
  .header {
    padding: 4rem 6rem;
  }
  .header-graphics img{
    max-width: 300px;
  }
  .header h1 {
    font-size: 48px;
  }
  .header h2 {
    margin: 0;
    padding: 0;
    font-size: 28px;
  }

  .header-content_btn {
    margin-top: 1rem;
  }

  .header-content_btn-folio {
    padding: 1px 1px;
    width: 160px;
    height: 45px;
  }
  .header-content_btn-folio p {
    font-size: 18px;
  }

  .header-content_btn-talk {
    padding: 1px;
    width: 160px;
    height: 45px;
  }

  .header-content_btn-talk p {
    font-size: 18px;
  }
}

@media screen and (max-width: 1050px){
  .header {
    padding: 4rem 3rem;
    align-items: center;
  }
  .header-graphics img{
    max-width: 350px;
  }
  .header-content-social img{
    width: 1.5rem;
  }
  .header-content-social{
    justify-content: flex-start;
    margin-top: 1rem;
  }
  
}
  


@media screen and (max-width: 900px) {
  .header {
    flex-direction: column-reverse;
    padding: 2rem 2rem;
    justify-content: center;
    align-items: center;
  }
  .header-graphics{
    justify-content: center;
  }

  .header-graphics img {
    max-width: 200px;
  }
  .header h1 {
    font-size: 38px;
    padding-top: 40px;
    line-height: 38px;
  }
  .header h2 {
    padding-top: 0;
    line-height: 24px;
    padding-top: 8px;
    padding-bottom: 24px;
    font-size: 20px;
  }
  .header-content{
    align-items: center;
  }

  .header-content_btn {
    margin-top: 0.5rem;
  }

  .header-content_btn-folio {
    padding: 1px 1px;
    width: 120px;
    height: 35px;
    border-radius: 16px;
  }
  .header-content_btn-folio:hover{
     border: 2px solid var(--color-light);
     width: 116px;
    height: 31px;
  }
   .header-content_btn-talk {
    padding: 1px 1px;
    width: 120px;
    height: 35px;
    border-radius: 16px;
  }

   .header-content_btn-talk:hover{
     border: 2px solid var(--color-text);
     width: 116px;
      height: 31px;
  }


  .header-content_btn-folio p {
    font-size: 12px;
  }
  .header-content_btn-talk p {
    font-size: 12px;

  }

  .header-content_btn-folio img{
    width: 1.2rem;
  }

  .header-content_btn-talk img{
    width: 1.2rem;
  }



}

@media screen and (max-width: 500px) {

}

.navbar {
  display: flex;
  align-items: center;
  padding: 1rem 6rem;
}
.navbar-border {
  height: 2px;
  width: 100%;
  background-color: var(--color-text);
}

.navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-links_container {
  display: flex;
  justify-content: flex-end;
}

.navbar-links_logo p {
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 28.43px;
  line-height: 43px;
  color: var(--color-light);
  cursor: pointer;
}

.navbar-links_container p {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: var(--color-white);
  margin-left: 8rem;
}
.navbar-menu_toggle {
  display: none;
} 

@media screen and (max-width: 1050px) {
  .navbar-links_container p {
    margin-left: 4rem;
  }
  .navbar{
    padding: 1rem 3rem;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    display: flex;
    align-items: center;
    padding: 1rem 2.5rem;
  }

  .navbar-links_container {
    display: none;
  }

  .navbar-menu_toggle {
    display: flex;
  }

  .navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    text-align: end;
    padding:0.5rem 1rem;
    color: white;
    position: absolute;
    top: 3.5rem;
    right:2.5rem;
    min-width: 80px;
    background-color: var(--color-text);
    border-radius: 5px;
    z-index: 1;
}
.navbar-links_container-links{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.navbar-menu_container-links p{
      font-family: var(--font-primary);
      font-size: 18px;
      padding: 0.5rem;
  }
  .navbar-menu_container-links p:hover{
    color: var(--color-bg);
    font-size: 18px;
    font-weight: 500;
}
  
}

@media screen and (max-width: 500px) {
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 36px;
  height: 18rem;
  width: 16rem;
  background: rgba(222, 214, 255, 0.2);
  border-radius: 16px;
}

.card h5 {
margin-top: 2rem;
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 28.43px;
  line-height: 43px;
  max-width: 200px;
  color: var(--color-white);
}

.card p {
  margin-top: 1rem;
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color:var(--color-light);
}

@media screen and (max-width: 1300px) {
  .card{
    height: 12rem;
    width: 10rem;
  }
  .card h5{
    margin-top: 1rem;
    font-size: 20px;
    line-height: 28px;
  }
  .card p{
    margin-top: 0.5rem;
    font-size: 12px;
    line-height: 16px;
  }
  .card img{
    width: 3rem;
  }
}

@media screen and (max-width: 850px) {
  .card{
    height: 10rem;
    width: 8rem;
  }
  .card h5{
    margin-top: .5rem;
    font-size: 14px;
    line-height: 18px;
  }
  .card p{
    margin-top: 0.5rem;
    font-size: 8px;
    line-height: 12px;
  }
  .card img{
    width: 2.5rem;
  }
  
}

@media screen and (max-width: 700px) {
  .card{
    margin-top: 2rem;
    height: 14rem;
    width: 12rem;
  }
  .card h5{
    margin-top: 2rem;
    font-size: 18px;
    line-height: 18px;
  }
  .card p{
    margin-top: 1rem;
    font-size: 14px;
    line-height: 18px;
  }
  .card img{
    width: 3rem;
  }
  
}


.about {
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
}

.about p {
  text-align: center;
  color: var(--color-text);
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 50.52px;
  line-height: 76px;
}

.about-profile {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 4rem;
}

.about-profile p {
  max-width: 800px;
  font-family: var(--font-primary);
  font-weight: 400;
  font-size: 21.33px;
  line-height: 32px;
  color: var(--color-light);
}

.about-education{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1300px) {
  
  .about p {
    font-size: 38px;
  
  }
  .about-profile img{
    width: 8rem;
  }
  
  .about-profile p {
    text-align: right;
    font-size: 16px;
    line-height: 24px;
    
  }
  
  .about-education img:first-child{
    width: 30rem;
  }
  .about-education img:last-child{
    width: 12rem;
  }
}

@media screen and (max-width: 700px) {
  .about-education img:last-child{
    display: none;
  }
  .about-education img:first-child{
    width: 25rem;
  }
  .about-profile {
    flex-direction: column;
  }
  .about-profile p{
    margin-top: rem;
    text-align: center;
    font-size: 14px;
  }

}


@media screen and (max-width: 500px) {
  .about p {
    font-size: 24px;
}
.about-profile p {
  max-width: 1000px;
  font-family: var(--font-primary);
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-light);
}
.about-education img:first-child {
  width: 23rem;
}
}

.skills-card {
  padding: 0rem 6rem 8rem 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.skills{
  display: flex;
  flex-direction: column;
}

.skills-logo{
  display: flex;
  grid-gap: 5rem;
  gap: 5rem;
  text-align: center;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.skills-logo img{
  display: flex;
}

@media screen and (max-width: 1050px) {
  .skills-card{
    padding: 2rem 3rem;

  }
  .skills-logo img{
    width: 1.5rem;
  }
  .skills-logo{
    margin-top: 2rem;
    grid-gap: 4rem;
    gap: 4rem;
  }
}

@media screen and (max-width: 700px) {
  .skills-card{
    flex-direction: column;
    justify-content: center;
  }
  .skills-logo{
    margin-top: 2rem;
    grid-gap: 3rem;
    gap: 3rem;
  }
  
}

@media screen and (max-width: 500px) {
  .skills-logo{
    margin-top: 2rem;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

