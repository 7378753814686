@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;300;400;500;600;700;800&display=swap');

:root{
  --font-primary:'Poppins',sans-serif;
  --font-secondary:'Open-Sans',sans-serif;

  --gradient-text: linear-gradient(49deg, #DED6FF 0%, #9074FF 93.93%);
  --color-bg:#0A0324;
  --color-text:#8970EF;
  --color-light:#DED6FF;
  --color-white:#fff;

}

html {
  -webkit-tap-highlight-color: transparent;
}

